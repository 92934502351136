<template>
  <vue-seamless-scroll :data="listData" :class-option="classOption" class="warp">
    <div class="buttom" v-for="(item, index) in listData" :key="index">
      <strong class=strong v-html="item.text"></strong>
    </div>
  </vue-seamless-scroll>
</template>

<script>
import vueSeamlessScroll from 'vue-seamless-scroll'

export default {
  components: {
    vueSeamlessScroll
  },
  data() {
    return {
      listData: [
        { text: '專 業 匯 款  &nbsp &nbsp信 心 保 證' },],
      classOption: {
        limitMoveNum: 1,
        direction: 2,
      }
    }
  },
}
</script>

<style scoped>
.warp {
  width: 1080px;
  overflow: hidden;
  background-color: #e60213;
}

.buttom {
  width: 1080px;
  background-color: #e60213;
  margin: 1px;
}

.strong {
  color: white;
  font-size: 50px;
  font-weight: 400;
}


</style>