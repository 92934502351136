<template>
  <div class="carousel">
    <div class="imgDiv">
      <div v-for="(value, index) in carousel.imgSrc" >
          <img class="img" :src="value.src" v-show="carousel.showIndex === index + 1" :key="value.id"/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Carousel",
  data() {
    return {
      carousel: {
        imgSrc: [
          { src: require("../assets/1.jpg"), id: 1 },
          { src: require("../assets/2.jpg"), id: 2 },
          { src: require("../assets/3.jpg"), id: 3 },
        ],
        showIndex: 1,
      },
      animation: "come",
    };
  },
  methods: {
    addIndex() {
      if (this.carousel.showIndex < this.maxIndex) {
        this.carousel.showIndex += 1;
      } else {
        this.carousel.showIndex = 1;
      }
    },
    minusIndex() {
      if (this.carousel.showIndex > 1) {
        this.carousel.showIndex -= 1;
      } else {
        this.carousel.showIndex = this.maxIndex;
      }
    },
    setInterval() {
      this.timer = setInterval(() => {
        this.addIndex(); //轮播图4秒加一次
      }, 4000);
    },
  },
  computed: {
    maxIndex() {
      return this.carousel.imgSrc.length;
    },
  },
  mounted() {
    this.setInterval();
  },
  destroyed() {
    window.clearInterval(this.timer); //离开当前组件就清除掉
  },
};
</script>

<style scoped>
.img {
  width: 1080px;
  border: 0;
}
.imgDiv {
  flex-direction: row;
  display:block
}
</style>