<template>
  <div class="app">
    <div class="line" style=" margin-top: -10px;">
      <strong style="color:red;font-size: 110px;text-shadow: 1px 1px 1px #000000;margin-top:30px ;">各 國 貨 幣 牌 價 表</strong>
    </div>
    <div class="line">
      <strong style="color:red;margin-left: 10px;font-size: 50px;margin-top: -10px;">(免 收 手 續 費)</strong>
    </div>
    <div class="line">
      <strong style="color:red;font-size: 48px;margin-top:5px">MONEY CHARGE RATES(NO COMMISSION)</strong>
    </div>
    <div class="line1">
      <div style="font-size: 38px;width: 300px;margin-left: -5px;"><strong
          style="color:red;font-size: 40px;">幣種</strong> CURRENCY</div>
      <div style="font-size: 38px;width: 180px;margin-right: 65px;"><strong
          style="color:red;font-size: 40px;">買入</strong> BUY</div>
      <div style="font-size: 38px;width: 180px;margin-right: 65px;"><strong
          style="color:red;font-size: 40px;">賣出</strong> SELL</div>
    </div>
    <Form1></Form1>
    <div class="line3">
      <div style="color: #f8c77eee;font-size: 30px;width: 850px;">{{ str }}  {{ time }}</div>
      <Form2></Form2>
    </div>
    <div style="font-size:0">
    <div class="line4"></div>
    <Carousel></Carousel></div>
    <div class="line4"></div>
  </div>
</template>
<script>
import Form1 from '@/components/Form1.vue'
import Form2 from '@/components/Form2.vue'
import Carousel from '@/components/Carousel.vue'
export default {
  name: 'App',
  components: {
    Form1, Form2,Carousel
  },
  data() {
    return {
      time: '',
      str: '',
    }
  },
  methods: {
    updateTime() {
      const now = new Date();
      let a = new Array("日", "一", "二", "三", "四", "五", "六");
      let week = new Date().getDay();
      this.str = now.getFullYear() + '-' + (now.getMonth() + 1) + '-' + now.getDate() + ' ' + "星期" + a[week]
      if (now.toLocaleTimeString() < '12:00:00') {
        this.time = '上午' + ' ' + now.toLocaleTimeString();
      } else {
        this.time = '下午' + ' ' + now.toLocaleTimeString();
      }

    }
  },
  mounted() {
    this.updateTime();
    setInterval(() => {
      this.updateTime();
    }, 1000);

  }
}
</script>
<style scoped>
.app {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.line {
  display: flex;
  background-color: #d7ab695f;
  width: 1080px;
  justify-content: center;
  align-items: center;
}

.line1 {
  width: 1080px;
  display: flex;
  text-align: center;
  justify-content: space-around;
  background-color: #e69f35ce;
  overflow: hidden;
}

.line3 {
  width: 1080px;
  display: flex;
  text-align: center;
  align-items: center;
  background-color: #e60213;
}
.line4{
  width: 1080px;
  background-color: #e60213;
  height: 100px;
}
@media screen and (max-width: 1000px) {
  .line {
    width: 100vw;
  }

  .line1 {
    width: 100vw;
  }

  .line3 {
    width: 100vw;
  }
  .line4{
  width: 100vw;
}
}
</style>